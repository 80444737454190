import React, {createContext, useContext, useEffect, useState} from "react";
import { getRGBAColor } from '../libs/getColor';
import getAddress from "../libs/ssrStartRequst/getAddress";
import getAllProducts from "../libs/ssrStartRequst/getAllProducts";
import {localStorageWrap} from "../libs/helpers/localStorageWrap";
import { Preloader } from "../components/common/preloader"; 
import {apiClient, getCurrentDomain, setVkMiniAppDomain} from "../libs/api/apiClient"
import bridge from "@vkontakte/vk-bridge";
import { useNavigate } from "react-router-dom";
import NotFoundInVkGroup from "../components/common/VkPageNotFoundInVkGroup";
import ServerError from "../pages/500";
import NotLinkedToCrm from "../components/common/VkPageNotLinkedToCrm";
import 'react-toastify/dist/ReactToastify.css';
import getBase from "../libs/ssrStartRequst/getBase";

interface AppContextType {
  company: any;
  city: any;
  branch: any;
  updateCurrentBranch: () => Promise<void>;
  address: any;
  allProducts: any;
  setAddress: (value: any) => void;
  openTimeInfo: any;
  setOpenTimeInfo: (value: any) => void;
  reloadWithoutReload: (showPreloader?: boolean) => any;
  vkCommunityMessagesAllowed: boolean;
  setVkCommunityMessagesAllowed: (value: boolean) => void;
  vkLaunchParams: any;
  notFound: boolean;
  addressClarificationPopupShow: boolean,
  setAddressClarificationPopupShow: (value: any) => void;
  yandexRating: any;
  setYandexRating: (value: any) => void;
}

const AppContext = createContext<AppContextType>({
  company: null,
  city: null,
  branch: null,
  updateCurrentBranch: async () => {},
  address: null,
  allProducts: null,
  setAddress: () => {},
  openTimeInfo: true,
  setOpenTimeInfo: () => {},
  reloadWithoutReload: async (showPreloader?: boolean) => {},
  vkCommunityMessagesAllowed: false,
  setVkCommunityMessagesAllowed: () => {},
  vkLaunchParams: null as any,
  notFound: false,
  addressClarificationPopupShow: false,
  setAddressClarificationPopupShow: () => {},
  yandexRating: 0,
  setYandexRating: () => {},
});

type Props = {
  children: React.ReactNode,
}

export async function getAllInitial(host: string) {
  let logs: any = {host}
  const {company, branch, city} = await getBase()

  if (!company) return {company: null}
  
  if (city) {
    const [address, allProducts] = await Promise.all([
      getAddress(city, branch),
      getAllProducts(host, branch),
    ])

    logs = {...logs, 
      redirect: city.slug,
      company,
      city,
      notFound: false,
      branch,
      address,
      allProducts,
    }

    return {
      redirect: city.slug,
      company,
      city,
      notFound: false,
      branch,
      address,
      allProducts,
      logs
    };
  } else {
    logs = {...logs, company, notFound: true}
    return { notFound: true, logs }
  }
}

export function AppContextWrapper(props: Props) {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [company, setCompany] = useState<any>()
  const [city, setCity] = useState<any>()
  const [branch, setBranch] = useState<any>()
  const [address, setAddress] = useState<any>()
  const [allProducts, setAllProducts] = useState<any>()
  const [openTimeInfo, setOpenTimeInfo] = useState()
  const [vkCommunityMessagesAllowed, setVkCommunityMessagesAllowed] = useState<any>();
  const [vkLaunchParams, setVkLaunchParams] = useState<any>();
  const [getAllInitialLogs, setGetAllInitialLogs] = useState<any>()
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [noGroupError, setNoGroupError] = useState(false)
  const [noCompanyError, setNoCompanyError] = useState(false)
  const [addressClarificationPopupShow, setAddressClarificationPopupShow] = useState(false)
  const [yandexRating, setYandexRating] = useState(0)

  // Инициализация приложения
  async function VKInit() {
    //Логика для загрузки вк мини апп
    let vkLaunchParams: any = null;
          
    let result = await bridge.send("VKWebAppInit");

    // Это вместо bridge.send("VKWebAppGetLaunchParams"). Мы сделали так потому что на некоторых телефонах непонятно почему не хочет работать этот метод и
    // такой вариант предложила нам поддержка ВК
    vkLaunchParams = window?.location?.search?.slice(1)?.split('&')?.reduce((acc, item) => ({...acc, [item.split('=')[0]]: item.split('=')[1]}), {}) || await bridge.send("VKWebAppGetLaunchParams");

    await localStorageWrap.init(vkLaunchParams.vk_group_id);
    localStorageWrap.removeItem('visitorId')
    localStorageWrap.removeItem('actionsFromAuth')

    if (!vkLaunchParams?.vk_group_id) {
      return {status: 'NoGroup', vkUserLoaded: false}
    }

    setVkMiniAppDomain(vkLaunchParams.vk_app_id, vkLaunchParams.vk_group_id)
    console.log("vk mini app init completed", result, vkLaunchParams)

    if (vkLaunchParams?.vk_group_id) {
      (async () => {
        let vkCommunityMessagesAllowedOnStart = false;
        try {
          let res = await bridge.send('VKWebAppAllowMessagesFromGroup', {group_id: +vkLaunchParams.vk_group_id})
          vkCommunityMessagesAllowedOnStart = res.result;
        } catch(e) {}
        setVkCommunityMessagesAllowed(vkCommunityMessagesAllowedOnStart)
      })().then();
    }

    setVkLaunchParams(vkLaunchParams)
    return { vkUserLoaded: true, status: 200, vkLaunchParams }
  }

  async function InitApp(VKInitData: any) {
    try {
      const {status, vkUserLoaded, vkLaunchParams} = VKInitData
  
      if (!vkUserLoaded) {
        if (status == 'NoGroup') {
          setNoGroupError(true)
        } else {
          setServerError(true)
        }
        setLoaded(true)
        return
      }
  
      const {
        redirect,
        company,
        city,
        notFound,
        branch,
        address,
        allProducts,
        logs
      } = await getAllInitial(getCurrentDomain())
  
      console.log({
        redirect,
        company,
        city,
        notFound,
        branch,
        address,
        allProducts,
        logs
      })
  
      const userInfo = await bridge.send('VKWebAppGetUserInfo')
  
      const vkAuth = await apiClient.auth.loginVkMiniApp({...vkLaunchParams, name: `${userInfo.first_name} ${userInfo.last_name}`}, branch.id)
  
      if (vkAuth.status === 404) {
        setNoCompanyError(true)
        setLoaded(true)
        return
      }
  
      if (vkAuth?.user && vkAuth?.token) {
        localStorageWrap.setItem('user', JSON.stringify({...vkAuth.user, token: vkAuth.token}))
        localStorageWrap.setItem('vkUserLoaded', 'true');
        localStorageWrap.setItem('actionsFromAuth', JSON.stringify(vkAuth.actions))
        console.log("vk login successful")
      } else {
        localStorageWrap.setItem('vkUserLoaded', 'false');
        console.log("vk login failed", vkAuth)
        setServerError(true)
        setLoaded(true)
        return 
      }
  
      console.log('loaded city', city, {notFound})
      setNotFound(!!notFound)
      setCompany(company)
      setCity(city)
      setBranch(branch)
      localStorageWrap.setItem('branchId', branch.id + '');
      setAddress(address)
      setAllProducts(allProducts)
      setGetAllInitialLogs(logs)
      setLoaded(true)
  
      if (!notFound) {
        localStorageWrap.setItem('city', redirect)
        navigate(`/${redirect}`)
      }
    } catch (e) {
      setServerError(true)
      setLoaded(true)
      console.log('getAllInitial error: ', e)
    }
  }

  useEffect(() => {
    VKInit().then(InitApp)
  }, [])

  const updateCurrentBranch = async () => {
    if (branch) {
      const { data } = await apiClient.branch.get(branch?.id)
      if (data) setBranch(data)
    }
  }

  async function reloadWithoutReload(showPreloader: boolean = false) {
    if (showPreloader) setLoaded(false);

    const {company, branch, city} = await getBase()
  
    if (!company) return {company: null}
    
    const [address, allProducts] = await Promise.all([
      getAddress(city, branch),
      getAllProducts(getCurrentDomain(), branch),
    ])

    setCity(city)
    setBranch(branch)
    setAddress(address)
    setAllProducts(allProducts)
    if (showPreloader) setLoaded(true);
  }

  useEffect(() => {
    if (company?.domain) {
      document.addEventListener('click', (event: any) => {
        let target = event.target.closest('a')
        
        if (target) {
          console.dir(target)
          if (target?.href?.includes(company.domain)) {
            event.preventDefault()
            navigate(target?.href?.split('/').slice(3).join('/'))
          }
        }
      })
    }
  }, [company?.domain])

  useEffect(() => {
    console.log(getAllInitialLogs)
  }, [getAllInitialLogs])

  if (!loaded)
    return <div className={"flex h-screen justify-center items-center borer-2 border-red-500"}>
      <Preloader countOfDot={4} color={'gray-30'}/>
    </div>

  if (serverError)
    return <ServerError/>
  
  if (noGroupError)
    return <NotFoundInVkGroup vkLaunchParams={vkLaunchParams}/>

  if (noCompanyError || !company)
    return <NotLinkedToCrm/>

  return (
    <AppContext.Provider
      value={{
        company,
        city,
        branch, updateCurrentBranch,
        address, setAddress,
        allProducts,
        openTimeInfo, setOpenTimeInfo,
        reloadWithoutReload,
        vkLaunchParams,
        vkCommunityMessagesAllowed, setVkCommunityMessagesAllowed,
        notFound,
        addressClarificationPopupShow,
        setAddressClarificationPopupShow,
        yandexRating,
        setYandexRating,
      }}
    >
      <style>:root {`{${getRGBAColor(company.template.mainColor, 'main')} ${getRGBAColor(company.template.additionalColor, 'additional')} ${getRGBAColor(company.template.orderButtonColor, 'orderButton')}}`}</style>
      <div className={`${company.template.theme}`}>
        <div className="bg-light dark:bg-dark text-dark dark:text-light">
          {props.children}
        </div>
      </div>
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}


